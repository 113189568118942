/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 82번째 로그에서는 화상회의 앱, 클라우드 서비스 성장, 버추얼 컨퍼런스, AWS 엘리멘탈 링크 등에 대해 이야기를 나눴습니다."), "\n", React.createElement(_components.h3, null, "당근마켓 MAU 700만"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://platum.kr/archives/140802"
  }, "당근마켓, 월간 이용자 700만…압도적 모바일 중고거래 플랫폼 1위 - 'Startup's Story Platform’")), "\n"), "\n", React.createElement(_components.h3, null, "클라우드 서비스 성장"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://techcrunch.com/2020/04/30/amazon-q1-beats-on-net-sales-of-75-5b-but-posts-net-income-of-2-5b-down-1b-on-a-year-ago/?tpcc=ECTW2020"
  }, "Amazon Q1 beats on net sales of $75.5B but posts net income of $2.5B, down $1B on a year ago | TechCrunch")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/JonErlichman/status/1255953221350232065?ref_src=twsrc%5Etfw"
  }, "Jon Erlichman on Twitter: \"Amazon’s cloud revenue in Q1: ...")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://techcrunch.com/2020/04/19/alibaba-cloud-will-invest-28-billion-more-into-its-infrastructure-over-the-next-three-years/"
  }, "Alibaba Cloud will invest $28 billion more into its infrastructure over the next three years | TechCrunch")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.alibabacloud.com/help/doc-detail/40654.htm"
  }, "Regions and zones - General Reference| Alibaba Cloud Documentation Center")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.canalys.com/newsroom/canalys-china-cloud-infrastructure-Q4-2019"
  }, "Canalys Newsroom- Canalys: China cloud companies quick to help combat coronavirus")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://landing.google.com/sre/sre-book/chapters/embracing-risk/"
  }, "Chapter 3 Embracing Risk - Site Reliability Engineering")), "\n"), "\n", React.createElement(_components.h3, null, "화상회의 앱"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.oracle.com/corporate/pressrelease/zoom-selects-oracle-to-support-growth-042820.html"
  }, "Zoom Selects Oracle as a Cloud Infrastructure Provider for Its Core Online Meeting Service")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.theverge.com/2020/4/30/21242421/zoom-300-million-users-incorrect-meeting-participants-statement"
  }, "Zoom admits it doesn’t have 300 million users, corrects misleading claims - The Verge")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://meet.google.com/"
  }, "Google Meet")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.cnet.com/how-to/messenger-rooms-heres-how-to-use-facebooks-free-new-video-chat-app-now/"
  }, "Messenger Rooms: Here's how to use Facebook's free new video chat app now - CNET")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://duo.google.com/about/"
  }, "Google Duo - Free High-Quality Video Calling App")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.cnet.com/how-to/messenger-rooms-heres-how-to-use-facebooks-free-new-video-chat-app-now/"
  }, "Messenger Rooms: Here's how to use Facebook's free new video chat app now - CNET")), "\n"), "\n", React.createElement(_components.h3, null, "버추얼 컨퍼런스"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://register.build.microsoft.com/"
  }, "Microsoft Build 2020 - Home - Home")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.theverge.com/2020/3/3/21163553/google-io-2020-cancelled-coronavirus-tech-developer-conference"
  }, "Google cancels I/O 2020, its biggest event of the year - The Verge")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://developer.apple.com/wwdc20/"
  }, "WWDC20 - Apple Developer")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.writethedocs.org/blog/moving-portland-prague-2020-online/"
  }, "Write the Docs 2020 conferences going virtual — Write the Docs")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.pycon.kr/2020"
  }, "파이콘 한국 2020")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/gvanrossum/status/1255575530264358913"
  }, "Guido van Rossum on Twitter: \"PyCon 2020 in Pittsburgh didn't happen -- but PyCon Online is happening! ...")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://us.pycon.org/2020/online/"
  }, "PyCon US")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://reinvent.awsevents.com/"
  }, "AWS re:Invent 2020 | Amazon Web Services")), "\n"), "\n", React.createElement(_components.h3, null, "AWS 엘리멘탈 링크 출시"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/medialive/features/link/"
  }, "AWS Elemental Link")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/media-services/"
  }, "AWS 미디어 서비스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/media-services/"
  }, "AWS 미디어 서비스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/channel/UCj7FkILntTrAzITWw4MWEFA"
  }, "AWS Elemental - YouTube")), "\n"), "\n", React.createElement(_components.h3, null, "팀 브레이 AWS 퇴사"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://techcrunch.com/2020/05/04/aws-engineer-tim-bray-resigns-from-amazon-following-worker-firings/"
  }, "AWS engineer Tim Bray resigns from Amazon following worker firings | TechCrunch")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.tbray.org/ongoing/When/202x/2020/04/29/Leaving-Amazon"
  }, "ongoing by Tim Bray · Bye, Amazon")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Tim_Bray"
  }, "Tim Bray - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.tbray.org/ongoing/When/201x/2014/02/19/Leaving-Google"
  }, "ongoing by Tim Bray · Leaving Google")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
